<template>
  <div class="MemberRegistration">
    <div class="logo">
      <a href="">
        <img
          src="../../assets/images/register_logo.png"
          alt=""
        >
        <p>一站式工业仪器仪表交易平台-买卖仪器仪表就上仪商城</p>
      </a>
    </div>
    <div class="register-box">
      <div class="register-wrapper">
        <div class="register">
          <!-- <p class="title"><img src="../../assets/images/B_l.png" alt="" class="Pimg"></p> -->
          <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
          >
            <el-tab-pane
              label="个人注册"
              name="second"
            >
              <el-form
                :model="ruleForm1"
                :rules="rules1"
                ref="ruleForm1"
                label-width="0"
                class="demo-ruleForm"
                style="border:none;"
              >
                <el-form-item prop="name">
                  <el-input
                    v-model="ruleForm1.name"
                    auto-complete="off"
                    placeholder="请输入用户名"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="tel">
                  <el-input
                    v-model="ruleForm1.tel"
                    auto-complete="off"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="pass">
                  <el-input
                    type="password"
                    v-model="ruleForm1.pass"
                    auto-complete="off"
                    placeholder="输入密码"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="checkPass">
                  <el-input
                    type="password"
                    v-model="ruleForm1.checkPass"
                    auto-complete="off"
                    placeholder="确认密码"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="smscode"
                  class="code"
                >

                  <div class="code">
                    <el-input
                      v-model="ruleForm1.smscode"
                      placeholder="验证码"
                      style="float:left;width:180px;"
                    ></el-input>
                    <el-button
                      type="primary"
                      :disabled='yzmDisabled1'
                      @click="sendCode"
                      style="background:#e94c15;border-color:#e94c15;height:40px;float:left;"
                    >{{codeInput1}}</el-button>
                  </div>
                </el-form-item>
                <p
                  style="height:30px;color: #F56C6C;"
                  v-if="Vcerror"
                >验证码错误,请重新输入</p>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm('ruleForm1')"
                    style="width:100%"
                  >注册</el-button>

                  <el-row>
                    <el-col :span="2">
                      <p class="protocol">
                        <el-checkbox v-model="checked1"></el-checkbox>
                      </p>
                    </el-col>
                    <el-col
                      :span="22"
                      style="line-height:20px"
                    >我已经审慎阅读、充分理解
                      <router-link
                        tag="a"
                        target="_blank"
                        class="hover"
                        :to="{path:'/agreement'}"
                      > 《仪商城服务条款》</router-link>

                      中相关条款内容</el-col>
                  </el-row>

                  <p
                    class="login"
                    @click="gotoLogin"
                  >已有账号？立即登录</p>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane
              label="企业注册"
              name="first"
            >
              <el-form
                :model="ruleForm2"
                :rules="rules2"
                ref="ruleForm2"
                label-width="0"
                class="demo-ruleForm"
                style="border:none;"
              >
                <el-form-item prop="name">
                  <el-input
                    v-model="ruleForm2.name"
                    auto-complete="off"
                    placeholder="请输入用户名"
                  ></el-input>
                </el-form-item>

                <el-form-item prop="company_name">
                  <el-input
                    v-model="ruleForm2.company_name"
                    auto-complete="off"
                    placeholder="请输入企业名称"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="company_contacts">
                  <el-input
                    v-model="ruleForm2.company_contacts"
                    auto-complete="off"
                    placeholder="请输入企业联系人"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="company_industry">
                  <el-select
                    style="width:100%"
                    v-model="ruleForm2.company_industry"
                    placeholder="请选择行业"
                  >
                    <el-option
                      style="padding:0 10px "
                      v-for="item in listData"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="tel">
                  <el-input
                    v-model="ruleForm2.tel"
                    auto-complete="off"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="pass">
                  <el-input
                    type="password"
                    v-model="ruleForm2.pass"
                    auto-complete="off"
                    placeholder="输入密码"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="checkPass">
                  <el-input
                    type="password"
                    v-model="ruleForm2.checkPass"
                    auto-complete="off"
                    placeholder="确认密码"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="smscode"
                  class="code"
                >
                  <el-input
                    v-model="ruleForm2.smscode"
                    placeholder="验证码"
                    style="float:left;width:180px;"
                  ></el-input>
                  <el-button
                    type="primary"
                    :disabled='yzmDisabled2'
                    @click="En_sendCode"
                    style="background:#e94c15;border-color:#e94c15;height:40px;float:left;"
                  >{{codeInput2}}</el-button>
                </el-form-item>
                <p
                  style="height:30px;color: #F56C6C;"
                  v-if="Vcerror"
                >验证码错误,请重新输入</p>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm1('ruleForm2')"
                    style="width:100%;"
                  >注册</el-button>

                  <el-row
                    align="top"
                    type="flex"
                  >
                    <el-col :span="2">
                      <p class="protocol">
                        <el-checkbox v-model="checked2"></el-checkbox>
                      </p>
                    </el-col>
                    <el-col
                      :span="22"
                      style="line-height:20px"
                    >我已经审慎阅读、充分理解
                      <router-link
                        tag="a"
                        target="_blank"
                        class="hover"
                        :to="{path:'/agreement'}"
                      > 《仪商城服务条款》</router-link>
                      中相关条款内容</el-col>
                  </el-row>
                  <p
                    class="login"
                    @click="gotoLogin"
                  >已有账号？立即登录</p>

                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="open"
      width="30%"
    >
      <p>恭喜您，已注册成功！</p>
      <p style="color:#fe6925">温馨提示：要实名认证了才能购物哦！ </p>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="toHome">确定</el-button>
        <!-- <el-button
          type="primary"
          @click="toZc"
        >去实名认证</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    let checkname = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    // <!--验证手机号是否合法-->
    let checkTel = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("请输入正确的手机号"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }
    };
    //  <!--验证码是否为空-->
    let checkSmscode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机验证码"));
      } else {
        callback();
      }
    };
    let checkcompany_name = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入企业名称"));
      } else {
        callback();
      }
    };
    let checkcompany_contacts = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入企业联系人"));
      } else {
        callback();
      }
    };
    let checkindustry = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入所在行业"));
        // callback(new Error('请输入正确的企鹅搜咋子'))
      } else {
        callback();
      }
    };
    // <!--验证密码-->
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        //   if (this.ruleForm1.checkPass !== "") {
        //     this.$refs.ruleForm1.validateField("checkPass");
        //   }
        //   callback()
        // }
        var reg = /^[a-zA-Z0-9]*(([a-zA-Z]+[0-9]+)|([0-9]+[a-zA-Z]+))[a-zA-Z0-9]*$/;
        if (value) {
          if (!reg.test(value)) {
            return callback(new Error("密码必须由数字和字母组合成"));
          } else {
            callback();
          }
        }
      }
    };
    let validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        //   if (this.ruleForm2.checkPass !== "") {
        //     this.$refs.ruleForm2.validateField("checkPass");
        //   }
        //   callback()
        // }
        var reg = /^[a-zA-Z0-9]*(([a-zA-Z]+[0-9]+)|([0-9]+[a-zA-Z]+))[a-zA-Z0-9]*$/;
        if (value) {
          if (!reg.test(value)) {
            return callback(new Error("密码必须由数字和字母组合成"));
          } else {
            callback();
          }
        }
      }
    };
    // <!--二次验证密码-->
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm1.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let validatePass12 = (rule, value, callback) => {
      console.log("value", value);
      console.log("pass", this.ruleForm2.pass);
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm2.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      open: false,
      checked1: undefined,
      checked2: undefined,
      listData: [],
      telCode: "",
      checked: false,
      activeName: "second",
      Vcerror: false,
      ruleForm1: {
        name: "",
        pass: "",
        checkPass: "",
        tel: "",
        smscode: ""
      },
      ruleForm2: {
        name: "",
        pass: "",
        checkPass: "",
        tel: "",
        smscode: "",
        company_name: "",
        company_contacts: "",
        company_industry: ""
      },
      rules1: {
        name: [{ validator: checkname, trigger: "change" }],
        pass: [
          { validator: validatePass, trigger: "change" },
          { min: 8, message: "密码长度不小于8个字符", trigger: "change" }
        ],
        checkPass: [{ validator: validatePass2, trigger: "change" }],
        tel: [{ validator: checkTel, trigger: "change" }],
        smscode: [{ validator: checkSmscode, trigger: "change" }]
      },
      rules2: {
        name: [{ validator: checkname, trigger: "change" }],
        pass: [
          { validator: validatePass1, trigger: "change" },
          { min: 8, message: "密码长度不小于8个字符", trigger: "change" }
        ],
        checkPass: [{ validator: validatePass12, trigger: "change" }],
        tel: [{ validator: checkTel, trigger: "change" }],
        smscode: [{ validator: checkSmscode, trigger: "change" }],
        company_name: [{ validator: checkcompany_name, trigger: "change" }],
        company_contacts: [
          { validator: checkcompany_contacts, trigger: "change" }
        ],
        company_industry: [{ validator: checkindustry, trigger: "change" }]
      },
      codeInput1: "发送验证码",
      codeInput2: "发送验证码",
      isDisabled1: false, // 是否禁止点击发送验证码按钮
      isDisabled2: false,
      flag: true,
      time2: 0,
      time1: 0,
      yzmDisabled2: false,
      yzmDisabled1: false

      // up:falag,`
    };
  },
  mounted() {
    this.getIndustry();
  },
  methods: {
    toHome() {
      this.open = false;
      this.$router.push({
        path: "/login"
      });
    },
    // toZc() {
    //   this.open = false;

    //   this.$router.push({ path: "/member-setting", query: { activeName: 3 } });
    // },
    // <!--进入登录页-->
    gotoLogin() {
      this.$router.push({
        path: "/login"
      });
    },
    //获取行业
    getIndustry() {
      this.$get("index.php/admin/hyzy", { type: 1 }).then(res => {
        console.log("行业", res);

        res.data.forEach(item => {
          this.listData.push({
            label: item.value,
            value: item.id
          });
        });
        // this.listData[0].unshift({ label: "", value: "请选择" });
        console.log("listData", this.listData);
      });
    },
    // <!--发送验证码-->
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    //个人注册1分钟倒计时
    timer1() {
      if (this.time1 > 0) {
        this.yzmDisabled1 = true;
        this.time1--;
        this.codeInput1 = this.time1 + "秒";
        setTimeout(this.timer1, 1000);
      } else {
        this.time1 = 0;
        this.codeInput1 = "发送验证码";
        this.yzmDisabled1 = false;
      }
    },
    //企业注册1分钟倒计时
    timer2() {
      if (this.time2 > 0) {
        this.yzmDisabled2 = true;
        this.time2--;
        this.codeInput2 = this.time2 + "秒";
        setTimeout(this.timer2, 1000);
      } else {
        this.time2 = 0;
        this.codeInput2 = "发送验证码";
        this.yzmDisabled2 = false;
      }
    },
    sendCode() {
      this.$post("home/user/find_mobile", {
        mobile: this.ruleForm1.tel
      }).then(() => {
        this.$post("home/user/find_mobile", {
          username: this.ruleForm1.name,
          type: 1
        }).then(() => {
          this.$post("home/user/code", { mobile: this.ruleForm1.tel }).then(
            res => {
              console.log("code", res);

              this.time1 = 300;
              this.timer1();
            }
          );
        });
      });
    },
    En_sendCode() {
      this.$post("home/user/find_mobile", {
        mobile: this.ruleForm2.tel
      }).then(() => {
        this.$post("home/user/find_mobile", {
          username: this.ruleForm2.name,
          type: 1
        }).then(() => {
          this.$post("home/user/code", { mobile: this.ruleForm2.tel }).then(
            res => {
              console.log("code", res);

              this.time2 = 300;
              this.timer2();
            }
          );
        });
      });
    },
    // <!--个人提交注册-->
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!this.checked1) {
          this.$message.warning(" 请勾选已阅读《仪商城服务条款》");
          return false;
        }
        this.$post("home/user/reg", {
          username: this.ruleForm1.name,
          mobile: this.ruleForm1.tel,
          password: this.ruleForm1.pass,
          password_two: this.ruleForm1.checkPass,
          code: this.ruleForm1.smscode,
          member_type: 1
          // sex:this.optionlabel
        }).then(res => {
          this.open = true;
        });
      });
    },
    // <!--企业提交注册-->
    submitForm1(formName) {
      this.$refs[formName].validate(valid => {
        if (!this.checked2) {
          this.$message.warning(" 请勾选已阅读《仪商城服务条款》");
          return false;
        }
        let data = {
          username: this.ruleForm2.name,
          mobile: this.ruleForm2.tel,
          password: this.ruleForm2.pass,
          password_two: this.ruleForm2.checkPass,
          code: this.ruleForm2.smscode,
          member_type: 2,
          company_industry: this.ruleForm2.company_industry,
          company_contacts: this.ruleForm2.company_contacts,
          company_name: this.ruleForm2.company_name
        };
        console.log("data", data);
        this.$post("home/user/reg", data).then(res => {
          this.open = true;
        });
      });
    },

    // 验证手机号
    checkMobile(str) {
      let re = /^1\d{10}$/;
      if (re.test(str)) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.MemberRegistration {
  background: #fff;
}
.el-tabs__header {
  border: none !important;
}
#tab-first {
  border: none !important;
}
.logo {
  background: #fff;

  width: 100%;
  box-sizing: border-box;
  margin: 0px auto;
  height: 36px;
  padding: 20px 0 70px;
  img {
    width: 150px;
    height: 50px;
    float: left;
  }
  p {
    float: left;
    line-height: 72px;
    margin-left: 10px;
    color: #000;
    font-size: 25px;
    font-family: "KaiTi", Georgia, Serif;
  }
}
.loading-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #aedff8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register-wrapper img {
  position: absolute;
  z-index: 1;
}
.register-box ::after {
  clear: both;
  display: block;
  content: "";
}
.register-wrapper {
  background: url(../../assets/images/register_main.jpg) #e94c15 no-repeat
    center center;

  position: relative;
  width: 100%;
  height: 750px;
  overflow: hidden;
}

.register {
  box-shadow: 0 2px 8px #ddd;
  width: 400px;
  right: 80px;

  position: absolute;
  height: 94%;
  top: 3%;
  background: #fff;
  padding: 20px 40px;

  z-index: 9;
}
.title {
  font-size: 26px;
  line-height: 50px;
  height: 50px;
}
.Pimg {
  // width: 340px;
  height: 50px;
}
.el-form-item {
  // text-align: center;
  // width: 340px;
  margin-bottom: 20px;
}
.login {
  margin-top: 10px;
  // margin-left: -7px;
  font-size: 12px;
  line-height: 22px;
  color: #ee7f57;
  cursor: pointer;
  text-align: left;
  text-indent: 8px;
  // width: 160px;
  float: right;
}
.login:hover {
  color: #e94c15;
}
.protocol {
  .el-checkbox {
    float: left;
  }
  span {
    margin-left: 5px;
    display: block;
    float: left;
    color: #666;
    cursor: pointer;
  }
  span:hover {
    text-decoration: underline;
  }
}
.code {
  @include space-between;
}
.code >>> .el-form-item__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.code button {
  margin-left: 20px;
  width: 120px;
  text-align: center;
}
.el-button--primary:focus {
  background: #e94c15;
  border-color: #e94c15;
  color: #fff;
}
.demo-ruleForm {
  margin: 0;
}
.el-form-item__content {
  margin-bottom: 0px;
}
.el-input__inner {
  padding: 0 !important;
  text-indent: 15px !important;
  border-radius: 0 !important;
}
</style>
<style lang="scss">
.telName {
  position: absolute;
  top: 30%;
  left: 40%;
}
.GoLoginName {
  position: absolute;
  top: 30%;
  left: 40%;
}
</style>
